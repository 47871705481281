<template>
	<v-card class="mb-2">
		<v-list two-line>
			<v-list-item
				v-for="invoice in invoices"
				:key="invoice.id"
				:to="{ name: 'invoice', params: { id: invoice.id } }"
			>
				<v-list-item-content class="pr-2 invoice-nr">
					<v-list-item-subtitle>Nr</v-list-item-subtitle>
					<v-list-item-title>{{ invoice.number | shorten }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-content class="invoice-sum">
					<v-list-item-subtitle>Belopp</v-list-item-subtitle>
					<v-list-item-title>
						{{ invoice.amount.total | $_invoiceMixin_formatMoney }}
						kr
					</v-list-item-title>
				</v-list-item-content>

				<v-list-item-content class="invoice-date">
					<v-list-item-subtitle>{{ dateSubtitle }}</v-list-item-subtitle>
					<v-list-item-title>
						{{ invoice.date[datePath] | dateTime }}
					</v-list-item-title>
				</v-list-item-content>

				<v-list-item-action>
					<v-btn icon>
						<v-icon :color="iconColor(invoice)">{{ getIcon(invoice) }}</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';
import invoiceMixin from '@/mixins/invoiceMixin';

export default {
	filters: {
		dateTime(date) {
			return spacetime(`${date}`, 'Europe/Stockholm').unixFmt('yyyy-MM-dd');
		},
		shorten(number) {
			if (window.innerWidth > 450) {
				return number;
			}

			const numberString = number.toString();
			if (numberString.length > 6) {
				return numberString.replace(/^(\d{1})\d+(\d{3})$/, '$1...$2');
			}

			return number;
		},
	},
	mixins: [invoiceMixin],
	props: {
		invoices: {
			type: Array,
			required: true,
		},
		dateSubtitle: {
			type: String,
			required: true,
		},
		datePath: {
			type: String,
			required: true,
		},
	},
	methods: {
		getIcon(invoice) {
			const now = spacetime.now('Europe/Stockholm');
			if (invoice.paid === 'Y') {
				return 'mdi-information-outline';
			}

			if (spacetime(invoice.date.due, 'Europe/Stockholm').isBefore(now)) {
				return 'mdi-alert-octagram';
			}

			if (
				spacetime(invoice.date.due, 'Europe/Stockholm').isBetween(
					now,
					now.add(7, 'days'),
				)
			) {
				return 'mdi-alert';
			}

			return 'mdi-information';
		},
		iconColor(invoice) {
			const now = spacetime.now('Europe/Stockholm');
			if (invoice.paid === 'Y') {
				return 'green lighten-1';
			}

			if (invoice.paid === 'P' || invoice.paid === 'O') {
				return 'warning';
			}

			if (spacetime(invoice.date.due, 'Europe/Stockholm').isBefore(now)) {
				return 'error';
			}

			if (spacetime(invoice.date.due).isBetween(now, now.add(7, 'days'))) {
				return 'warning';
			}

			return 'gray lighten-1';
		},
	},
};
</script>

<style>
.invoice-nr {
	min-width: 70px;
}
.invoice-sum {
	min-width: 80px;
}
.invoice-date {
	min-width: 83px;
}
.v-list-item__action {
	margin-left: 8px !important;
}
</style>
