<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<div v-if="invoices">
			<a-invoice-late-alert :invoices="invoices" />
			<a-invoice-upcoming-alert :invoices="invoices" />

			<template v-if="lateInvoices.length > 0">
				<v-subheader class="title">Förfallna fakturor</v-subheader>
				<a-invoices-card
					:invoices="lateInvoices"
					date-subtitle="Förföll"
					date-path="due"
				/>
			</template>

			<template v-if="dueInvoices.length > 0">
				<v-subheader class="title">Obetalda fakturor</v-subheader>
				<a-invoices-card
					:invoices="dueInvoices"
					date-subtitle="Förfaller"
					date-path="due"
				/>
			</template>

			<template v-if="paidInvoices.length > 0">
				<v-subheader class="title"> Betalda fakturor </v-subheader>
				<a-invoices-card
					:invoices="paidInvoices"
					date-subtitle="Betald"
					date-path="paid"
				/>
			</template>
		</div>

		<div v-else>
			<a-loading
				:loading="$apollo.loading"
				type="card-heading, list-item-two-line@1"
			/>

			<a-loading
				:loading="$apollo.loading"
				type="card-heading, list-item-two-line@3"
			/>
		</div>
	</v-container>
</template>

<script>
import spacetime from 'spacetime';
import ALoading from '@/components/Loading';
import AInvoicesCard from '@/components/InvoicesCard';
import AInvoiceLateAlert from '@/components/InvoiceLateAlert';
import AInvoiceUpcomingAlert from '@/components/InvoiceUpcomingAlert';
import { INVOICES } from '@/graphql';

export default {
	components: {
		ALoading,
		AInvoicesCard,
		AInvoiceLateAlert,
		AInvoiceUpcomingAlert,
	},
	apollo: {
		invoices: {
			query: INVOICES,
			update: data => data.invoices,
		},
	},
	computed: {
		lateInvoices() {
			return this.invoices
				.filter(invoice => !this.isPaid(invoice))
				.filter(this.isLate)
				.sort(this.byLatestDate);
		},
		paidInvoices() {
			return this.invoices.filter(this.isPaid).sort(this.byLatestDate);
		},
		dueInvoices() {
			return this.invoices
				.filter(invoice => !this.isPaid(invoice))
				.filter(invoice => !this.isLate(invoice))
				.sort(this.byEarliestDate);
		},
	},
	methods: {
		isPaid(invoice) {
			const { paid } = invoice;
			return paid === 'Y' || paid === 'O';
		},
		isLate(invoice) {
			const dueDate = spacetime(invoice.date.due, 'Europe/Stockholm');
			const now = spacetime.now('Europe/Stockholm');

			return dueDate.isBefore(now);
		},
		byLatestDate(a, b) {
			const aDue = spacetime(a.date.due, 'Europe/Stockholm');
			const bDue = spacetime(b.date.due, 'Europe/Stockholm');
			return aDue.isBefore(bDue) ? 1 : -1;
		},
		byEarliestDate(a, b) {
			const aDue = spacetime(a.date.due, 'Europe/Stockholm');
			const bDue = spacetime(b.date.due, 'Europe/Stockholm');
			return aDue.isBefore(bDue) ? -1 : 1;
		},
	},
	metaInfo: {
		title: 'Fakturor',
	},
};
</script>

<style></style>
